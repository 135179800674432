import React from "react";
import data from "../../MyData";
import styles from "./SocialMedia.module.scss";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

const SocialMedia = () => (
  <div className={styles.SocialMedia}>
    <ul className="container">
      {data.socialMedia.map((socialLink, uniqueKey) => {
        return (
          <li key={uniqueKey}>
            <a
              href={socialLink.url}
              rel="noopener noreferrer"
              className="btn btn-link btn-sm btn-dark text-white p-1 m-2"
              aria-label={`${socialLink.icon}`}
              target={socialLink.target}
            >
              {socialLink.icon === "githubIcon" ? (
                <FaGithub />
              ) : (
                <FaLinkedinIn />
              )}
            </a>
          </li>
        );
      })}
    </ul>
  </div>
);

export default SocialMedia;
