import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Jumbotron } from "../common_functions";
// Education Logos
import UCLogo from "../../images/asserts/cumberlands-logo.svg";
import HULogo from "../../images/asserts/harrisburg_logo.svg";
import JNTULogo from "../../images/asserts/jntuh_logo.png";
// Certification Logos
import PCAPLogo from "../../images/asserts/PCAP_Certified_Associate_Python_Programmer_Badge.png";
import MicrosoftAzureLogo from "../../images/asserts/microsoft-certified-fundamentals-badge.svg";
import ZendLogo from "../../images/asserts/zce-2017-php-80x80.webp";
import JSALogo from "../../images/asserts/JSA_Certified_Associate_JavaScript_Programmer_Badge.png";
import BoiaLogo from "../../images/asserts/Bureau_of_Internet_Accessibility.svg";
import CPCDLogo from "../../images/asserts/cradlepoint_certifited_developer_badge.png";
import PMECLogo from "../../images/asserts/PMEC.webp";
import OracleSE8Logo from "../../images/asserts/Oracle_Java_SE_8_Associates_Badge.png";
import OracleCloudInfraLogo from "../../images/asserts/Oracle_Cloud_Infrastructure_Foundations_Associate.png";
import CiscoPythonE2Logo from "../../images/asserts/cisco_python_essentials_2.png";
import CiscoCyberSecurityLogo from "../../images/asserts/cisco_intro_cybersecurity.png";
import { GoVerified } from "react-icons/go";

const educationData = [
  {
    id: 1,
    fromDate: "2024",
    toDate: "2026",
    degree: "Doctor of Philosophy - PhD",
    institution: "University of the Cumberlands",
    location: "Williamsburg, KY, USA",
    major: "Information Technology",
    logo: {
      logo: UCLogo,
      width: "90%",
    },
  },
  {
    id: 2,
    fromDate: "2021",
    toDate: "2023",
    degree: "Masters Degree",
    institution: "University of the Cumberlands",
    location: "Williamsburg, KY, USA",
    major: "Information Technology",
    logo: {
      logo: UCLogo,
      width: "90%",
    },
  },
  {
    id: 3,
    fromDate: "2016",
    toDate: "2018",
    degree: "Masters Degree",
    institution: "Harrisburg University of Science and Technology",
    location: "Harrisburg, PA",
    major: "Computer Information Sciences",
    logo: {
      logo: HULogo,
      width: "100%",
    },
  },
  {
    id: 4,
    fromDate: "2010",
    toDate: "2014",
    degree: "Bachelors Degree",
    institution: "Jawaharlal Nehru Technological University",
    location: "Hyderabad, India",
    major: "Computer Science Engineering",
    logo: {
      logo: JNTULogo,
      width: "100%",
    },
  },
];

const certificationData = [
  {
    id: 11,
    issuedDate: "Sept 2024",
    expireDate: "Never",
    certificationName: "PCAP - Certified Associate Python Programmer",
    institution: "Python Institute",
    icon: PCAPLogo,
    verify:
      "https://www.credly.com/badges/e837ef2c-a069-404a-a86d-e80140ee7ce2/public_url",
  },
  {
    id: 12,
    issuedDate: "June 2024",
    expireDate: "Never",
    certificationName: "Microsoft Certified: Azure Fundamentals",
    institution: "Microsoft",
    icon: MicrosoftAzureLogo,
    verify:
      "https://learn.microsoft.com/en-us/users/uttejreddykota/credentials/2c7dfc578f020f9f",
  },
  {
    id: 13,
    issuedDate: "November 2023",
    expireDate: "Never",
    certificationName: "JSA - Certified Associate JavaScript Programmer",
    institution: "JS Institute",
    icon: JSALogo,
    verify:
      "https://www.credly.com/badges/3baff980-f50f-44bf-bd32-a230288ce2c3/public_url",
  },
  {
    id: 14,
    issuedDate: "March 2019",
    expireDate: "Never",
    certificationName: "Zend Certified Engineer, PHP 7.1",
    institution: "Zend",
    icon: ZendLogo,
    verify: "#",
  },
  {
    id: 15,
    issuedDate: "October 2023",
    expireDate: "Never",
    certificationName: "Project Management Essentials Certified (PMEC)™",
    institution: "Management & Strategy Institute",
    icon: PMECLogo,
    verify: "#",
  },
  {
    id: 16,
    issuedDate: "September 2021",
    expireDate: "November 2025",
    certificationName: "Cradlepoint Certified Developer",
    institution: "Cradlepoint",
    icon: CPCDLogo,
    verify:
      "https://www.credly.com/badges/258fca29-6103-4019-a8a0-96b2907d9b7c/public_url",
  },
  {
    id: 17,
    issuedDate: "March 2019",
    expireDate: "Never",
    certificationName: "Oracle Certified Associate, Java SE 8 Programmer",
    institution: "Oracle",
    icon: OracleSE8Logo,
    verify:
      "https://www.credly.com/badges/98211c4e-779e-4dc0-b612-0d21649d685d/public_url",
  },
  {
    id: 18,
    issuedDate: "October 2023",
    expireDate: "Never",
    certificationName: "Introduction to Cybersecurity",
    institution: "Cisco",
    icon: CiscoCyberSecurityLogo,
    verify:
      "https://www.credly.com/badges/d2753aad-300f-4ce6-b9e6-3e853893472c/public_url",
  },
  {
    id: 19,
    issuedDate: "October 2023",
    expireDate: "Never",
    certificationName: "Python Essentials 2",
    institution: "Cisco",
    icon: CiscoPythonE2Logo,
    verify:
      "https://www.credly.com/badges/9501a994-cb44-4f07-a109-25e12dd9e0db/public_url",
  },
  {
    id: 20,
    issuedDate: "April 2020",
    expireDate: "October 2021",
    certificationName:
      "Oracle Cloud Infrastructure Foundations Certified Associate",
    institution: "Oracle",
    icon: OracleCloudInfraLogo,
    verify:
      "https://www.credly.com/badges/b7e06c8d-5ae9-4d20-b267-032c0c01781f/public_url",
  },
  {
    id: 21,
    issuedDate: "December 2019",
    expireDate: "Never",
    certificationName: "Mobile Accessibility Best Practices Certificate",
    institution: "Bureau of Internet Accessibility",
    icon: BoiaLogo,
    verify: "#",
  },
];

const Education = React.forwardRef((props, ref) => {
  return (
    <Jumbotron
      id="education"
      className="bg-transparent text-light min-vh-100 d-flex flex-wrap align-content-center align-items-center m-0"
    >
      <Container>
        <Row>
          <h2 className="Section-Header text-center">Education</h2>
        </Row>
        <Row>
          {educationData.map((education, uniqueKey) => (
            <Col key={uniqueKey} lg={6} md={12} sm={12} className="mb-4">
              <Card
                className="text-center h-100"
                key={uniqueKey}
                border="success"
              >
                <div className="mx-auto">
                  <Card.Img
                    className={"px-3 py-3"}
                    style={{
                      height: "100px",
                      width:
                        education.logo.width === ""
                          ? "auto"
                          : education.logo.width,
                    }}
                    variant="top"
                    src={education.logo.logo}
                    alt={education.institution}
                  />
                </div>
                <Card.Header>
                  <div>
                    <strong>{education.degree}</strong>
                  </div>
                  <div>
                    <small>
                      <strong>{education.major}</strong>
                    </small>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Subtitle>{education.institution}</Card.Subtitle>
                  <Card.Subtitle className="pt-3">
                    <small>{education.location}</small>
                  </Card.Subtitle>
                </Card.Body>
                <Card.Footer className="text-muted">
                  {`${education.fromDate} - ${education.toDate}`}
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>

        <Row>
          <h2 className="Section-Header text-center">Certifications</h2>
        </Row>
        <Row>
          {certificationData.map((certificate, uniqueKey) => (
            <Col key={uniqueKey} lg={4} md={6} sm={12} className="p-0">
              <Card
                className="text-center h-100 flex-row align-content-center align-items-center bg-light"
                key={uniqueKey}
                border="success"
              >
                <Card.Img
                  className={"mx-3"}
                  style={{
                    height: "96px",
                    width: "96px",
                  }}
                  variant="left"
                  src={certificate.icon}
                  alt={certificate.certificationName}
                />
                <Card.Body>
                  <strong>{certificate.certificationName}</strong>
                  <br />
                  {certificate.institution}
                  <br />
                  <small className="text-muted">
                    {certificate.issuedDate}{" "}
                    {certificate.expireDate !== "Never"
                      ? " - " + certificate.expireDate
                      : ""}
                  </small>
                </Card.Body>
                <button
                  type="button"
                  className="btn btn-md float-end p-1"
                  id={uniqueKey}
                  aria-label={`${certificate.certificationName.toLowerCase()} Verify`}
                  style={
                    certificate.verify === "#" || certificate.verify === ""
                      ? { display: "none" }
                      : {}
                  }
                >
                  <a
                    href={certificate.verify}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                    className="btn btn-link btn-sm btn-light"
                    aria-label={`${certificate.certificationName} Verify`}
                  >
                    <GoVerified size={20} />
                  </a>
                </button>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </Jumbotron>
  );
});

Education.propTypes = {};

Education.defaultProps = {};

export default Education;
