import React from "react";
import data from "../../MyData";
import { FaGithub, FaLinkedinIn, FaHeart } from "react-icons/fa";

const Footer = () => (
  <div className={" text-center text-white"} id={"footer"}>
    <div className="container">
      <section className="mb-4">
        {data.footer.map((footer, uniqueKey) => {
          return (
            <a
              key={uniqueKey}
              className="btn btn-link btn-floating btn-lg text-dark m-1"
              rel="noopener noreferrer"
              role="button"
              aria-label={footer.ariaLabel}
              data-mdb-ripple-color="dark"
              href={footer.url}
              target={footer.target}
              id={`${footer.icon}-${uniqueKey}`}
            >
              {footer.icon === "githubIcon" ? (
                <FaGithub size={30} />
              ) : (
                <FaLinkedinIn size={30} />
              )}
            </a>
          );
        })}
      </section>
    </div>
    <div
      className="text-center text-dark p-3"
      style={{ letterSpacing: 3 + "px" }}
    >
      Designed with <FaHeart fill={"darkred"} /> by{" "}
      <a className="text-dark" href="https://www.iamkota.com">
        Uttej Reddy Kota
      </a>
    </div>
  </div>
);

export default Footer;
