import React from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Jumbotron } from "../common_functions";
import BIRLogo from "../../images/asserts/bir_company_logo.png"
import CPLogo from "../../images/asserts/cradlepoint_company_logo.png"
import RecologyLogo from "../../images/asserts/recology_company_logo.webp"
import MoxiworksLogo from "../../images/asserts/MoxiWorks_company_logo.webp"
// import VerticalLifeLogo from "../../images/asserts/Verticle_life_company_logo.png"
import MapicosLogo from "../../images/asserts/mapicos_company_logo.jpeg"

const experienceData = [
    {
        id: 1,
        fromDate: "December 2023",
        toDate: "Present",
        title: "Software Engineer",
        organization: "B.I.R Solution",
        location: "(Reno, NV)",
        url: {
            link: "https://birsolutions.com",
            logo: BIRLogo,
            width: ""
        }
    },
    {
        id: 2,
        fromDate: "June 2021",
        toDate: "November 2023",
        title: "Marketing Programming Manager, Web Operations and Developer ",
        organization: "Cradlepoint",
        location: "(Boise, ID)",
        url: {
            link: "https://cradelpoint.com",
            logo: CPLogo,
            width: "95%"
        }
    },
    {
        id: 3,
        fromDate: "Aug 2020",
        toDate: "May 2021",
        title: "Web Developer",
        organization: "Recology",
        location: "(Sacramento, CA)",
        url: {
            link: "https://recology.com",
            logo: RecologyLogo,
            width: "95%%"
        }
    },
    {
        id: 4,
        fromDate: "May 2019",
        toDate: "May 2020",
        title: "Software Engineer",
        organization: "MoxiWorks",
        location: "(Seattle, WA)",
        url: {
            link: "https://moxiworks.com",
            logo: MoxiworksLogo,
            width: "100%"
        }
    },
    // {
    //     id: 5,
    //     fromDate: "May 2017",
    //     toDate: "May 2018",
    //     title: "PHP Developer",
    //     organization: "Vertical life",
    //     location: "(Seattle, WA)",
    //     url: {
    //         link: "https://verticallife.com",
    //         logo: VerticalLifeLogo,
    //         width: "70%"
    //     }
    // },
    {
        id: 6,
        fromDate: "May 2014",
        toDate: "June 2016",
        title: "PHP Web Developer",
        organization: "Mapicos",
        location: "(Hyderabad, India)",
        url: {
            link: "#",
            logo: MapicosLogo,
            width: ""
        }
    }];

const Experience = React.forwardRef((props, ref) => {

    return (<Jumbotron id="experience"
                       className="bg-transparent text-light min-vh-100 d-flex flex-wrap align-content-center align-items-center m-0">
        <Container>
            <Row>
                <h2 className="Section-Header text-center">Experience</h2>
            </Row>
            <Row>
                {experienceData.map((experience, uniqueKey) => (
                    <Col key={uniqueKey} lg={4} md={12} sm={12} className="mb-4">
                        <Card className="text-center h-100" key={uniqueKey} border="success">
                            <div className="mx-auto">
                                <Card.Img className={"px-3 py-3"}
                                          style={{
                                              height: '100px',
                                              width: experience.url.width === "" ? 'auto' : experience.url.width
                                          }} variant="top" src={experience.url.logo} alt={experience.organization}/>
                            </div>
                            <Card.Header><strong>{experience.organization}</strong> <small>{experience.location}</small></Card.Header>
                            <Card.Body>
                                <Card.Subtitle>{experience.title}</Card.Subtitle>
                            </Card.Body>
                            <Card.Footer className="text-muted">
                                {`${experience.fromDate} - ${experience.toDate}`}
                            </Card.Footer>
                        </Card>
                    </Col>))}
            </Row>
        </Container>
    </Jumbotron>);
});

Experience.propTypes = {};

Experience.defaultProps = {};

export default Experience;
