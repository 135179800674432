import React from "react";
import { PopupWidget } from "react-calendly";
import Container from "react-bootstrap/Container";

const Calendar = () => (
  <Container className="text-center" id={"calendar"}>
    <PopupWidget
      url="https://calendly.com/uttejreddykota/30min"
      rootElement={document.getElementById("root")}
      text="Click here to schedule!"
      textColor="#ffffff"
      color="#4484ce"
    />
  </Container>
);

export default Calendar;
