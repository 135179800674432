import React from 'react';
import {Container, Nav, Navbar} from 'react-bootstrap';

// import logoImage from '../../images/Logos/Logos_Free_Size/Transparent_name.png';

function HeaderMenu() {
    return (
        <Navbar collapseOnSelect expand="lg" fixed="top" className="bg-body-tertiary site-header pt-0 pb-0">
            <Container>
                <Navbar.Brand href="#" className={"display-tc"}>
                    Uttej Kota
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto">
                        <Nav.Link href="#aboutme" className={"text-uppercase"}><strong>About Me</strong></Nav.Link>
                        <Nav.Link href="#education" className={"text-uppercase"}><strong>Education</strong></Nav.Link>
                        <Nav.Link href="#experience" className={"text-uppercase"}><strong>Experience</strong></Nav.Link>
                        <Nav.Link href="#projects" className={"text-uppercase"}><strong>Projects</strong></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default HeaderMenu;