import React from "react";
import Container from "react-bootstrap/Container";
import {Jumbotron} from "../common_functions";
import data from "../../MyData";
import {FaGithub, FaLinkedinIn} from "react-icons/fa";

const coverData = {
    gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
    prefixName: "Hey, I'm",
    firstName: "Uttej Reddy",
    middleName: "",
    lastName: "Kota",
    tagLine: "Software Engineer",
    infoText: "Software Engineer @ B.I.R Solutions | Ex-Cradlepoint | PhD Candidate | PHP Certified, JavaScript Certified, Oracle Certified, Mobile Accessibility Developer",
    icons: [
        {
            icon: "linkedinIcon",
            target: "_blank",
            url: "https://www.linkedin.com/in/uttejreddykota"
        },
        {
            icon: "githubIcon",
            target: "_blank",
            url: "https://github.com/uttejreddykota"
        }
    ]
};

const coverBackgroundStyle = {
    background: `linear-gradient(315deg,${coverData.gradientColors})`,
    animation: 'GradientBackgroundAnimation 30s ease infinite',
    backgroundSize: "1200% 1200%",
}

const MainBody = React.forwardRef(
    (props, ref) => {

        return (
            <Jumbotron
                fluid
                id="cover"
                style={coverBackgroundStyle}
                className="bg-transparent text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0">
                <div id="stars"></div>
                <Container className="text-center">
                    <h1 ref={ref}
                        className="cover-heading-primary py-3">{`${coverData.prefixName} ${coverData.firstName} ${coverData.middleName} ${coverData.lastName}`}</h1>
                    {/*<h4 className="text-black py-2"> {coverData.tagLine}</h4>*/}
                    <h4 className="text-black-50 py-2"> {coverData.infoText}</h4>
                    <div className="p-5">
                        {coverData.icons.map((icon, index) => (
                            <a key={`social-icon-${index}`}
                               target={icon.target}
                               className="btn btn-link btn-floating btn-lg text-dark m-1"
                               rel="noopener noreferrer" data-mdb-ripple-color="dark"
                               href={icon.url}
                               aria-label={`My ${icon.icon}`}>
                                {icon.icon === "githubIcon" ? <FaGithub size={30}/> : <FaLinkedinIn size={30}/>}
                            </a>
                        ))}
                    </div>
                </Container>
            </Jumbotron>
        );
    }
);

MainBody.propTypes = {};

MainBody.defaultProps = data.cover;

export default MainBody;
