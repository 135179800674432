// Project Images
import UPListImage
    from "./images/portfolio/Uplist/Uplist_ Real Estate Technology for Lenders, Agents & Homebuyers_ - getuplist.png"
import CradlepointImage
    from "./images/portfolio/Cradlepoint/Cradlepoint-VisibleSection.jpg"
import RecologyImage
    from "./images/portfolio/Recology/Recology Home - Compost, Recycling, & Landfill - Collection & Process_ - recology.jpeg"
import MoxiWorksImage from "./images/portfolio/MoxiWorks/MoxiWorks-VisibleSection Thumbnail.jpg"
import AOSImage from "./images/portfolio/AOS/Agents & Offices - Intero Real Estate Services - intero.jpeg"
import UpWellMortgageImage from "./images/portfolio/UpWellMortgage/UpwellMortgage-VisibleSection Thumbnail.jpg"
// import VerticallifeImage
//     from "./images/portfolio/Verticallife/Discover - Vertical Life XR - Augmented Virtual Mixed Reality - HOME_ - verticallife.jpeg"
import BookMyPartyImage from "./images/portfolio/BookMyParty/BookMyParty-VisibleSection Thumbnail.jpg"

// eslint-disable-next-line import/no-anonymous-default-export
export default {

    socialMedia: [
        {
            icon: "linkedinIcon",
            target: "_blank",
            url: "https://www.linkedin.com/in/uttejreddykota"
        },
        {
            icon: "githubIcon",
            target: "_blank",
            url: "https://github.com/uttejreddykota"
        }
    ],
    projects: {
        sectionHeader: "Projects",
        data: [
            {
                id: 2,
                title: "Uplist",
                para: "Real Estate Technology for Lenders to Connect",
                imageSrc: UPListImage,
                altText: "Uplist Home Page",
                url: "https://getuplist.com",
            },
            {
                id: 3,
                title: "Cradlepoint",
                para: "Wireless WAN & Private Cellular Solutions",
                imageSrc: CradlepointImage,
                altText: "Cradlepoint Home Page",
                url: "https://cradlepoint.com/",
            },
            {
                id: 4,
                title: "Recology",
                para: "Zero waste management",
                imageSrc: RecologyImage,
                altText: "Recology Home Page",
                url: "https://recology.com",
            },
            {
                id: 5,
                title: "Moxi Works",
                para: "Open real estate platform for residential agents and brokerages.",
                imageSrc: MoxiWorksImage,
                altText: "Moxi Works Home Page",
                url: "https://moxiworks.com",
            }, {
                id: 6,
                title: "Agent Office Search",
                para: "Search Agent and Office",
                imageSrc: AOSImage,
                altText: "Agent Office Search Intero Directory Page",
                url: "https://intero.com/directory",
            },
            {
                id: 7,
                title: "Upwell Mortgage",
                para: "Mortgage Loans, Rates, Calculators.",
                imageSrc: UpWellMortgageImage,
                altText: "Upwell Mortgage Home Page",
                url: "https://www.upwellmortgage.com/",
            },
            // {
            //     id: 8,
            //     title: "Vertical Life",
            //     para: "Augmented reality / Virtual Reality Mobile app for IOS and Andriod",
            //     imageSrc: VerticallifeImage,
            //     altText: "Vertical Life home page",
            //     url: "https://verticallife.com/",
            // },
            {
                id: 9,
                title: "Book My Party",
                para: "An online booking platform for all your party needs",
                imageSrc: BookMyPartyImage,
                altText: "Book My Party Home Page",
                url: "https://www.bookmyparty.com.au/",
            },

        ]
    },
    footer: [
        {
            icon: "linkedinIcon",
            target: "_blank",
            ariaLabel: "Linkedin Icon",
            url: "https://www.linkedin.com/in/uttejreddykota"
        },
        {
            icon: "githubIcon",
            target: "_blank",
            ariaLabel: "Github Icon",
            url: "https://github.com/uttejreddykota"
        }
    ]
}
