import ReactGA from "react-ga4";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./scss/_stars.scss";
import "./scss/_animations.scss";
import HeaderMenu from "./components/HeaderMenu/HeaderMenu";
import Cover from "./components/Cover/Cover";
import AboutMe from "./components/AboutMe/AboutMe";
import Projects from "./components/Projects/Projects";
import Education from "./components/Education/Education";
import Experience from "./components/Experience/Experience";
import Calendar from "./components/Calendar/Calendar";
import Footer from "./components/Footer/Footer";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    let TRACKING_ID = "G-8JZCHRN80J";
    if (TRACKING_ID !== "") {
      ReactGA.initialize(TRACKING_ID);
    }
  }, []);

  return (
    <div className="App">
      <HeaderMenu />
      <Cover />
      <AboutMe />
      <Education />
      <Experience />
      <Projects />
      <Calendar />
      <Footer />
    </div>
  );
}

export default App;
