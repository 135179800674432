import React from 'react';
import {Container, Badge, Row, Col} from 'react-bootstrap';
import {Jumbotron} from '../common_functions';
import SocialMedia from '../SocialMedia/SocialMedia';

const AboutMe = React.forwardRef((props, ref) => {
    const aboutData = {
        sectionHeader: "About Me", sectionSubHeader: "Get to know me!", content: {
            aboutParaOne: "I'm a Illinois based Software Engineer & Web Developer. I am a highly skilled PHP Web Developer and WordPress Developer with expertise in designing and developing user interfaces, Web Services, and managing production sites.",
            aboutParaTwo: "I hold a Master's degree in Computer and Information Sciences, Zend Certified Engineer - PHP 7.1, and Oracle Certified Associate - Java SE 8 Programmer. These certifications validate my expertise and commitment to delivering high-quality results. With a focus on attention to detail and a dedication to continuous learning, I am well-equipped to contribute to the success of any web development project or organization."
        },
    };
    const skillsData = {
        sectionHeader: "My Skills",
        data: ["PHP", "Laravel", "WordPress", "Azure", "AWS", "React", "CMS", "UI/UX Design", "HTML/HTML5", "CSS/CSS3", "JavaScript", "jQuery", "Node.js", "Java", "Angular",
            "Bitbucket", "Branding", "Cake PHP", "Data Studio", "Docker", "DNS", "Flywheel", "Git", "GitHub", "GA4", "Google Analytics", "InVision", "MySQL", "OneTrust", "SEMrush", "Uberflip", "Path factory",
            "WordPress Themes", "WordPress Plugins", "Email Marketing", "Security"],
    };

    return (<Jumbotron id="aboutme"
                       className="bg-transparent text-light min-vh-100 d-flex flex-wrap align-content-center align-items-center m-0">
        <Container>
            <Row className="mb-2">
                <h2 className="Section-Header text-center">{aboutData.sectionHeader}</h2>
            </Row>
            <Row>
                <Col lg={7} className="left">
                    <h3 className={"Section-SubHeader"}>{aboutData.sectionSubHeader}</h3>
                    <div className="Section-Body">
                        {Object.keys(aboutData.content).map((value) => (<p key={value}>{aboutData.content[value]}</p>))}
                    </div>
                    <SocialMedia/>
                </Col>
                <Col lg={5} className="right">
                    <h3 className={"Section-SubHeader"}>{skillsData.sectionHeader}</h3>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        {skillsData.data.map((skill, index) => (<Badge
                            key={index}
                            bg="dark" // Change the bg to "light" for light grey color
                            text="light"
                            className="m-1 p-1"
                            style={{
                                height: '40px',
                                minWidth: '50px',
                                display: 'inline-flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                justifyContent: 'center',
                            }}>
                            {skill}
                        </Badge>))}
                    </div>
                </Col>
            </Row>
        </Container>
    </Jumbotron>);
});

AboutMe.propTypes = {};

AboutMe.defaultProps = {};
export default AboutMe;
