import React from 'react';
import {Jumbotron} from "../common_functions";
import {Card, Col, Container, Row} from 'react-bootstrap';
import data from "../../MyData";
import {FaCircleArrowRight} from "react-icons/fa6";

const Projects = React.forwardRef((props, ref) => {

    return (<Jumbotron id="projects"
                       className="bg-transparent text-light min-vh-100 d-flex flex-wrap align-content-center align-items-center m-0">
        <Container>
            <Row>
                <h2 className="Section-Header text-center">Projects</h2>
            </Row>
            <Row>
                {Projects.defaultProps.data.map((project, uniqueKey) => (
                    <Col key={uniqueKey} lg={4} md={12} sm={12} className="mb-4">
                        <Card className="text-center h-100" key={uniqueKey}>
                            <div className="mx-auto">
                                <Card.Img
                                    variant="top" src={project.imageSrc} alt={project.altText}/>
                            </div>
                            <Card.Header>{project.title}</Card.Header>
                            <Card.Body>
                                {/*<Card.Title>{project.title}</Card.Title>*/}
                                <Card.Text>{project.para}</Card.Text>
                            </Card.Body>
                            <Card.Footer
                                className="text-muted">
                                <button type="button" className="btn btn-sm float-end" id={uniqueKey}
                                        aria-label={`${project.title.toLowerCase()} view more`}>
                                    <a href={project.url} target={"_blank"} rel={"noopener noreferrer"}
                                       aria-label={`${project.altText}`}>
                                        <FaCircleArrowRight/>
                                    </a>
                                </button>
                            </Card.Footer>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    </Jumbotron>)
        ;
});

Projects.propTypes = {};

Projects.defaultProps = data.projects;

export default Projects;
